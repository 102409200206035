import auth from '@/actions/authorizations/index.js'

export default class {
  execute(spec, component) {
    const t = TrelloPowerUp.iframe()

    $tpu.r.data.remove(t, "member", "private", $tpu.keys.mbrAuthToken()).then(() => {
      t.closeBoardBar()

      // Launch a new board because we want to make sure it has the correct size.
      auth.launchAuthorize(t)
    })
  }
}
